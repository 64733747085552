import React from "react";
import { useState } from "react";
import { Tooltip } from "./Tooltip";
import { Link } from "react-scroll";

let isDark = true;

const ClassicHeader = ({ handleNavClick, changeTheme }) => {
  const [stickyHeader, setStickyHeader] = useState(false);
  const [isNavModalClose, setIsNavModalClose] = useState(true);

  // const [isDark, setIsDark] = useState(true);

  const checkScrollTop = () => {
    let header = document.getElementsByClassName("primary-menu");

    if (header) {
      if (
        document.body.scrollTop > 180 ||
        document.documentElement.scrollTop > 180
      ) {
        setStickyHeader(true);
      } else {
        setStickyHeader(false);
      }
    }
  };

  const toggleTheme = () => {
    // console.log("toggleTheme  isDark before: ", isDark);
    isDark = ! isDark;
    changeTheme(isDark)
    // console.log("toggleTheme  isDark after: ", isDark);
    // setIsThemeChanged(false);
  };

  if (typeof window !== "undefined") {
    window.addEventListener("scroll", checkScrollTop);
  }

  return (
    <header id="header" className="sticky-top-slide">
      {/* Navbar */}
      <nav
        className={
          "primary-menu navbar navbar-expand-lg navbar-dark bg-transparent border-bottom-0 sticky-top " +
          (stickyHeader ? "sticky-on" : "")
        }
      >
        <div className="container-fluid position-relative g-lg-4">
          <div className="col-auto col-lg-2">
            {/* Logo */}
            <Link
              smooth
              duration={500}
              style={{ cursor: "pointer" }}
              className="logo"
              to="home"
              title="Anthony"
              onClick={(e) => {
                e.preventDefault();
                setIsNavModalClose(true);
              }}
            >
              {" "}
              <img 
               className="img-fluid rounded-pill d-block my-2"
               src="images/ai.png" 
              //  src="images/logo-light.png" 
               alt="Anthony" 
               width={"80"} height={"80"}/>{" "}
            </Link>
            {/* Logo End */}
          </div>
          <div className="col col-lg-8 navbar-accordion">
            <button
              onClick={(e) => {
                setIsNavModalClose(!isNavModalClose);
              }}
              className={
                isNavModalClose
                  ? "navbar-toggler ms-auto"
                  : "navbar-toggler ms-auto show"
              }
              id="navbar-toggler"
              type="button"
            >
              <span />
              <span />
              <span />
            </button>
            <div
              id="header-nav"
              className={
                isNavModalClose
                  ? "collapse navbar-collapse justify-content-center "
                  : "show navbar-collapse justify-content-center"
              }
            >
              <ul className="navbar-nav">
                <li className="nav-item">
                  <Link
                    smooth
                    duration={500}
                    style={{ cursor: "pointer" }}
                    spy
                    activeClass="active"
                    className="nav-link"
                    to="home"
                    onClick={(e) => {
                      e.preventDefault();
                      setIsNavModalClose(true);
                    }}
                  >
                    Home
                  </Link>
                </li>
                <li className="nav-item">
                  <Link
                    smooth
                    duration={500}
                    style={{ cursor: "pointer" }}
                    spy
                    activeClass="active"
                    className="nav-link"
                    to="about"
                    onClick={(e) => {
                      e.preventDefault();
                      setIsNavModalClose(true);
                    }}
                  >
                    About
                  </Link>
                </li>
                <li className="nav-item">
                  <Link
                    smooth
                    duration={500}
                    style={{ cursor: "pointer" }}
                    spy
                    activeClass="active"
                    className="nav-link"
                    to="services"
                    onClick={(e) => {
                      e.preventDefault();
                      setIsNavModalClose(true);
                    }}
                  >
                    What I Do
                  </Link>
                </li>
                <li className="nav-item">
                  <Link
                    smooth
                    duration={500}
                    style={{ cursor: "pointer" }}
                    spy
                    activeClass="active"
                    className="nav-link"
                    to="resume"
                    onClick={(e) => {
                      e.preventDefault();
                      setIsNavModalClose(true);
                    }}
                  >
                    Resume
                  </Link>
                </li>
                {/* <li className="nav-item">
                  <Link
                    smooth
                    duration={500}
                    style={{ cursor: "pointer" }}
                    spy
                    activeClass="active"
                    className="nav-link"
                    to="portfolio"
                    onClick={(e) => {
                      e.preventDefault();
                      setIsNavModalClose(true);
                    }}
                  >
                    Portfolio
                  </Link>
                </li> */}
                {/* <li className="nav-item">
                  <Link
                    smooth
                    duration={500}
                    style={{ cursor: "pointer" }}
                    spy
                    activeClass="active"
                    className="nav-link"
                    to="testimonial"
                    onClick={(e) => {
                      e.preventDefault();
                      setIsNavModalClose(true);
                    }}
                  >
                    Client
                  </Link>
                </li> */}
                <li className="nav-item">
                  <Link
                    smooth
                    duration={500}
                    style={{ cursor: "pointer" }}
                    spy
                    activeClass="active"
                    className="nav-link"
                    to="contact"
                    onClick={(e) => {
                      e.preventDefault();
                      setIsNavModalClose(true);
                    }}
                  >
                    Contact
                  </Link>
                </li>

                {/* <li  className="nav-item d-flex my-auto align-content-center" style={{ cursor: "pointer" }} >
                <div className="form-check form-switch" style={{ cursor: "pointer" }}>
                <input className="form-check-input" type="checkbox" role="switch" id="flexSwitchCheckChecked"  style={{ cursor: "pointer" }}
                 onChange={(e) => {
                  // e.preventDefault();
                  toggleTheme();
                }}
                checked={isDark} />
                
                <label className="form-check-label" htmlFor="flexSwitchCheckChecked" style={{ color: "white" }}> { ( isDark? "Switch to Light?" : "Switch to Dark?" ) }</label>
              </div>
              </li> */}

              </ul>
            </div>
          </div>
          <div className="col-auto col-lg-2 d-flex justify-content-end">
            <ul className="social-icons social-icons-light">
            <li  className="nav-item d-flex my-auto align-content-center" style={{ cursor: "pointer" }} >
                <div className="form-check form-switch" style={{ cursor: "pointer" }}>
                <input className="form-check-input" type="checkbox" role="switch" id="flexSwitchCheckChecked"  style={{ cursor: "pointer" }}
                 onChange={(e) => {
                  // e.preventDefault();
                  toggleTheme();
                }}
                checked={isDark} />
                
                <label className="form-check-label" htmlFor="flexSwitchCheckChecked" style={{ color: "white" }}> { ( isDark? "Light?" : "Dark?" ) }</label>
              </div>
              </li>
            {/* <li >
                <Tooltip text={ ( isDark? "Switch to Light?" : "Switch to Dark?" ) } placement="top">
                  <a
                    href="#"
                    rel="noopener noreferrer"
                    onClick={(e) => {
                      e.preventDefault();
                      toggleTheme();
                    }}
                  >
                  
                    <i className={ (  isDark? "fa-regular fa-lightbulb" : "fa-solid fa-moon" ) } />
                  </a>
                </Tooltip>
              </li> */}

              <li className="social-icons-linkedin">
                <Tooltip text="Linkedin" placement="top">
                  <a
                    href="https://linkedin.com/in/tonyidigbe/"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    <i className="fab fa-linkedin" />
                  </a>
                </Tooltip>
              </li>

          
              <li className="social-icons-medium">
                <Tooltip text="Medium" placement="top">
                  <a
                    href="https://medium.com/@templar.idigbe/"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    <i className="fab fa-medium" />
                  </a>
                </Tooltip>
              </li>
              <li className="social-icons-facebook">
                <Tooltip text="Facebook" placement="top">
                  <a
                    href="https://www.facebook.com/templar.idigbe/"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    <i className="fab fa-facebook-f" />
                  </a>
                </Tooltip>
              </li>
            </ul>
          </div>
        </div>
      </nav>
      {/* Navbar End */}
    </header>
  );
};

export default ClassicHeader;
