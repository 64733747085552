import React from "react";
import parse from 'html-react-parser';

// import * as DOMPurify from 'dompurify';

const Services = ({ classicHeader, darkTheme }) => {
  // services details
  const services = [
    {
      name: "Product Ownership/Management",
      desc: `
            <p>
            - Creating top notch sustainable products vision, strategies, roadmaps, goals for startups and organisations by using processes such as 'Design Thinking, 'Design Sprints', and 'Lean Startup' principles.
            </p>
            <p>
            - Decomposing and measuring feature values, validating them and clearly defining the metrics based on different evidence/hypothesis techniques.
            </p>
            <p>
            - Establishing and maintaining relationships with the various stakeholders (Customers, end-users, internal departments, development/operations teams).
            </p>
            <p>
            - Visualising data to monitor and measure outcomes. Performing extensive market research to establish potential value and keep abreast with the competition.
            </p>
            <p>
            - Constantly innovating and monitoring product release lifecycles!
            </p>
            <p>
            - Use of tools and frameworks such as Jira/Confluence, Azure DevOps, Scrum/Kanban, Miro/Maze/Visio, Figma, etc.
            </p>
      `,
      icon: "fas fa-palette",
    },
    {
      name: "Professional Scrum Management",  
      desc: `
      <p>
      - Implementing Agile best practices for software development across organisations. 
      </p>
      <p>
      - Building empowered and cross functional teams, Helping companies scale when and if needed
      </p>
      <p>
      -Facilitating the development teams, Product Owners, and Stakeholders to remove impediments, blockers and to resolve dependencies
      </p>
      <p>
      - Aiding businesses in defining high quality Acceptance Criteria and Definitions of Done (DoDs) for the iterations
      </p>
      <p>
      - Coaching teams to improve collaboration and provide better transparency, enabling data-driven decision
      making, increasing focus and turn-around time
      </p>
      <p>
      - Use of tools and frameworks such as Jira/Confluence, Azure DevOps, Scrum/Kanban, Miro/Maze/Visio, Figma, etc.
      </p>
      `,
      icon: "fas fa-desktop",
    },
    {
      name: "Business Data Analysis",
      desc: `
      <p>
      - Visualising data to monitor and measure outcomes. Performing extensive market research to establish potential  value and keep abreast with the competition.
      </p>
      <p>
      - Execute deep statistical analysis using languages and tools like SQL, Python, and R to enhance understanding of customer behaviour
      </p>
      <p>
      - Creating interactive data models that allow the related departments to visualise the impact of different dimensions and aid campaigns
      </p>
      <p>
      - Use of tools and frameworks such as Power BI, Looker Studio, Excel, and Google Analytics
      </p>
      
      `,
      icon: "fas fa-chart-area",
    },
    {
      name: "Application Design & Development",
      desc: `
      <p>
      - Empathise, Define, Ideate, Prototype, Test, Design, Develop and Maintain applications across various channels (Desktop, Mobile, Web, USSD, POS, Kiosks)
      </p>
      <p>
      -  Development with native and hybrid platforms and tools
      </p>
      <p>
      - Adhering to best practises and standards (TDD, DDD, QA, Agile/Waterfall, SOP, SDLC)
      </p>
      <p>
      - End to end process automations across the lifecycle, Version control, Use of collaborative tools ad frameworks and implementation of 'lean principles' to manage budgets
      </p>
      <p>
      - Use of tools and frameworks such as React, React-Native, JavaScript, MongoDB, SQL, Git, Terraform, Docker, etc.
      </p>
      `,
      icon: "fas fa-desktop",
    },
    // {
    //   name: 'Business "Start-Up/MVP" Consulting ',
    //   desc: `
    //   <p>
    //   Creating top notch sustainable products vision,strategies, roadmaps, goals for startups and organisations 
    //   </p>
    //   <p>
    //   Establishing and maintaining relationships with the various stakeholders (Customers, end-users, internal departments, development/operations teams).
    //   </p>
    //   <p>
    //   Visualising data to monitor and measure outcomes. Performing extensive market research to establish potential  value and keep abreast with the competition.
    //   </p>
    //   <p>
    //   Constantly innovating and monitoring the products release lifecycle!
    //   </p>
    //   `,
    //   icon: "fas fa-paint-brush",
    // },
    {
      name: "Cloud & Payment Integrations Services",
      desc: `
      <p>
       - Design of Software Architecture for custom business needs.
      </p>
      <p>
      - Provision of Software as a Service (SaaS), Infrastructure as a Service (IaaS), Platform as a Service (PaaS), Banking as a Service (BaaS)).
      </p>
      <p>
      - Integration of payment systems into mobile, web, desktop, USSD, POS, and Kiosk applications.
      </p>
      <p>
      - Use of cloud technologies such as AWS, Azure, GCP.
      </p>
      <p>
      - Integration of payment systems and services such as Stripe, PayStack, PayPal, TrueLayer, Maplerad.
      </p>
      `,
      icon: "fas fa-desktop",
      
    },
    
  ];

  return (
    <section
      id="services"
      className={"section " + (darkTheme ? "bg-dark-2" : "bg-light")}
    >
      <div className={"container " + (classicHeader ? "" : "px-lg-5")}>
        {/* Heading */}
        <div className="position-relative d-flex text-center mb-5">
          <h2
            className={
              "text-24  text-uppercase fw-600 w-100 mb-0 " +
              (darkTheme ? "text-white-50  opacity-1" : "text-black-50  opacity-3")
            }
          >
            Services
          </h2>
          <p
            className={
              "text-9  fw-600 position-absolute w-100 align-self-center lh-base mb-0 " +
              (darkTheme ? "text-white" : "text-dark")
            }
          >
            What I Do?
            <span className="heading-separator-line border-bottom border-3 border-primary d-block mx-auto" />
          </p>
        </div>
        {/* Heading end*/}
        {/* content start */}
        <div className="row">
          <div className="col-lg-12 mx-auto">
            <div className="row">
              {services.length > 0 &&
                services.map((service, index) => (
                  <div className="col-md-6" key={index}>
                    <div className="featured-box style-3 mb-5">
                      <div
                        className={
                          "featured-box-icon text-primary  shadow-sm rounded " +
                          (darkTheme ? "bg-dark-1" : "bg-white")
                        }
                      >
                        <i className={service.icon} />
                      </div>
                      <h3 className={darkTheme ? "text-white" : ""}>
                        {service.name}
                      </h3>
                      <div
                        className={"mb-0 " + (darkTheme ? "text-white-50" : "")}
                      >
                        { parse(service.desc) }
                      </div>
                    </div>
                  </div>
                ))}
            </div>
          </div>
        </div>
        {/* content end */}
      </div>
    </section>
  );
};

export default Services;
