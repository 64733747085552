import React from "react";
import resumeFile from "../documents/resume.pdf";

const Resume = ({ classicHeader, darkTheme }) => {
  const educationDetails = [
    {
      yearRange: "2006 - 2008",
      title: "MSc, Systems Engineering",
      place: "Budapest University of Technology and Economics, Budapest, Hungary",
      grade: "(Distinction)",
    },
    {
      yearRange: "2002 - 2006",
      title: "BSc, Software Engineering",
      place: "Budapest University of Technology and Economics, Budapest, Hungary",
      grade: "(First Class)",
    },
    {
      yearRange: "1993 - 1999",
      title: "BSc, Industrial Mathematics",
      place: "University of Benin, Nigeria",
      grade: "(2nd Class, Upper)",
    },
  ];

  const experienceDetails = [
    {
      yearRange: "2023 - Present",
      title: "Scrum Master",
      place: "Blackwing Productions | Rainham, London",
      domain: "Arts · Media Productions · Entertainment· Digital Media · Recruitment · Trainings ",
    },
    {
      yearRange: "2019 - 2022",
      title: "Digital Product Owner | Business Data Analyst",
      place: "Blackwing Productions | Rainham, London",
      domain: "Arts · Media Productions · Entertainment· Digital Media · Recruitment · Trainings ",
    },
    {
      yearRange: "2014 - 2019",
      title: "Head of Technology | Software Architect",
      place: "ITEX Integrated Ltd | Lagos, Nigeria",
      domain: "Fintech · B2C · B2B · E-commerce · Banking · Payments · Training · Agency · VAS"
    },
  ];

  const skills = [
    {
      name: "React, React-Native, JavaScript, Python, DAX",
      percent: 100,
      category:"Programming"
    },
    {
      name: " Jira, Confluence, Azure DevOps, Scrum, Miro, Maze, Figma, Visio, Slack",
      percent: 100,
      category:"Tools"
    },
   
    {
      name: "Power BI, Looker Studio, Google Analytics 4",
      percent: 100,
      category:"Visualizations"
    },
    {
      name: "MongoDB, SQL, Excel",
      percent: 100,
      category:"Data"
    },
    {
      name: "AWS, GCP, Azure,Git, Docker, Terraform",
      percent: 100,
      category:"Cloud & Automations"
    },
    {
      name: "Hypothesis Testing, Customer Segmentation, A/B Testing ",
      percent: 100,
      category:"Techniques"
    },
  ];

  return (
    <section
      id="resume"
      className={"section " + (darkTheme ? "bg-dark-1" : "")}
    >
      <div className={"container " + (classicHeader ? "" : "px-lg-5")}>
        {/* Heading */}
        <div className="position-relative d-flex text-center mb-5">
          <h2
            className={
              "text-24  text-uppercase fw-600 w-100 mb-0 " +
              (darkTheme ? "text-white-50  opacity-1" : "text-black-50  opacity-3")
              // (darkTheme ? "text-muted opacity-1" : "text-light opacity-4")
            }
          >
            Summary
          </h2>
          <p
            className={
              "text-9 text-dark fw-600 position-absolute w-100 align-self-center lh-base mb-0 " +
              (darkTheme ? "text-white" : "text-dark")
            }
          >
            {" "}
            Resume
            <span className="heading-separator-line border-bottom border-3 border-primary d-block mx-auto" />
          </p>
        </div>
        {/* Heading end*/}
        <div className="row gx-5">
          {/* My Education */}
          <div className="col-md-6">
            <h2
              className={
                "text-6 fw-600 mb-4 " + (darkTheme ? "text-white" : "")
              }
            >
              My Education
            </h2>
            {educationDetails.length > 0 &&
              educationDetails.map((value, index) => (
                <div
                  key={index}
                  className={
                    "bg-white  rounded p-4 mb-4 " +
                    (darkTheme ? "bg-dark" : "bg-white border")
                  }
                >
                  <p className="badge bg-primary text-2 fw-400">
                    {value.yearRange}
                  </p>
                  <h3 className={"text-5 " + (darkTheme ? "text-white" : "")}>
                    {value.title}, <span className={"text-3 fw-400 " + (darkTheme ? "text-primary" : "text-primary")}  > {value.grade} </span>
                  </h3>
                  <p className={darkTheme ? "text-primary" : "text-primary"}>
                    {value.place}
                    &nbsp;
                  </p>
                  <p className={"pb-2 pt-1 " + (darkTheme ? "text-white-50" : "")}>
                   &nbsp;
                  </p>
                </div>
              ))}
          </div>
          {/* My Experience */}
          <div className="col-md-6">
            <h2
              className={
                "text-6 fw-600 mb-4 " + (darkTheme ? "text-white" : "")
              }
            >
              Top Experiences
            </h2>
            {experienceDetails.length > 0 &&
              experienceDetails.map((value, index) => (
                <div
                  key={index}
                  className={
                    "bg-white  rounded p-4 mb-4 " +
                    (darkTheme ? "bg-dark" : "bg-white border")
                  }
                >
                  <p className="badge bg-primary text-2 fw-400">
                    {value.yearRange}
                  </p>
                  <h3 className={"text-5 " + (darkTheme ? "text-white" : "")}>
                    {value.title}
                  </h3>
                  <p className={darkTheme ? "text-primary" : "text-primary"}>
                    {value.place}
                  </p>
                  <p className={"mb-0 " + (darkTheme ? "text-white-50" : "")}>
                   <span className={"fw-500 " + (darkTheme ? "text-primary" : "text-primary")}  >Domain: </span>{value.domain}
                  </p>
                </div>
              ))}
          </div>
        </div>
         {/* My Skills */}
         <h2
          className={
            "text-6 fw-600 mt-4 mb-4 " + (darkTheme ? "text-white" : "")
          }
        >
          Technical Skills
        </h2>
        <div className="row gx-5">
          {skills.length > 0 &&
            skills.map((skill, index) => (
              <div key={index} className="col-md-6">
                <p
                  className={
                    " fw-500 text-start pb-1 " +
                    (darkTheme ? "text-light" : "text-dark")
                  }
                >
                  <span className={"fw-500 " + (darkTheme ? "text-primary" : "text-primary")}  >{skill.category} : </span>{skill.name}{" "}
                  {/* <span className="float-end">{skill.percent}%</span> */}
                </p>
                <div
                  className={
                    "progress progress-sm mb-4 " + (darkTheme ? "bg-dark" : "")
                  }
                >
                  <div
                    className="progress-bar"
                    role="progressbar"
                    style={{ width: skill.percent + "%" }}
                    // aria-valuenow={skill.percent}
                    aria-valuemin={0}
                    aria-valuemax={100}
                  />
                </div>
              </div>
            ))}
        </div>
         {/* Certifications */}
        <h2
          className={
            "text-6 fw-600 mt-4 mb-4 " + (darkTheme ? "text-white" : "")
          }
        >
          Certifications
        </h2>
        <div className="row gx-5">

        <div className="col-md-6 justify-content-center  d-flex ">
        <img 
               className="  d-block "
               src="images/certs/pspo1.png" alt="Anthony" 
               width={"300"} height={"300"}/>
               
         </div>
         <div className="col-md-6  justify-content-center  d-flex">
        <img 
               className=" d-block"
               src="images/certs/psm1.png" alt="Anthony" 
               width={"300"} height={"300"}/>
               
         </div>


      
          
        </div>
        <div className="text-center mt-5">
          <a
            className="btn btn-outline-secondary rounded-pill shadow-none"
            href={resumeFile}
            download
          >
            Download CV
            <span className="ms-1">
              <i className="fas fa-download" />
            </span>
          </a>
        </div>
      </div>
    </section>
  );
};

export default Resume;
