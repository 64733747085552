import React from "react";

const AboutUs = ({ classicHeader, darkTheme }) => {
  return (
    <section id="about" className={"section " + (darkTheme ? "bg-dark-1" : "")}>
      <div className={"container " + (classicHeader ? "" : "px-lg-5")}>
        {/* Heading */}
        <div className="position-relative d-flex text-center mb-5">
          <h2
            className={
              "text-24  text-uppercase fw-600 w-100 mb-0 " +
              (darkTheme ? "text-white-50  opacity-1" : "text-black-50  opacity-3")
              // (darkTheme ? "text-muted opacity-1" : "text-light opacity-4")
            }
          >
            About
          </h2>
          <p
            className={
              "text-9 text-dark fw-600 position-absolute w-100 align-self-center lh-base mb-0 " +
              (darkTheme ? "text-white" : "text-dark")
            }
          >
            About Anthony
            <span className="heading-separator-line border-bottom border-3 border-primary d-block mx-auto" />
          </p>
        </div>
        {/* Heading end*/}
        <div className="row py-2">
          {/* About me content start */}
          <div className="col-lg-12 col-xl-12 text-center text-lg-start text-5 fw-300">
            <h4
              className={
                "text-6 fw-500 mb-3 text-center " + (darkTheme ? "text-white" : "")
              }
            >
               PRODUCT OWNER <span className="text-primary">|</span> SCRUM MASTER <span className="text-primary">|</span> BUSINESS DATA ANALYST
             
            </h4>
            <p className={darkTheme ? "text-white-50" : ""}>
            As a technical product owner, scrum master,  business data analyst with over 10 plus years’ experience developing and
            delivering software products; collaborating with cross-functional teams, and managing product visions, roadmaps, backlogs, and releases, for more than 20 products serving more than 30 million people.
            </p>
            <p className={darkTheme ? "text-white-50" : ""}>
              I provide expertise in defining user-centric strategies to shape and deliver software products that serve more than 30 million people. A combination of creativity and critical thought underpins my ability to steer product maturation from MVP to stable releases, emphasising value-add and alignment with customer need. Design thinking methods, together with well-practised agile methodologies, ensure the consistent delivery of programmes and workstreams on time and within scope.
            </p>

            <p className={darkTheme ? "text-white-50" : ""}>
            Exemplary interpersonal and communication skills facilitate engagement and collaboration with stakeholders across functional and organisational boundaries. Positive and pragmatic, promoting productivity, efficiency, and achievement of outstanding results. Above all, prioritises an ethos of integrity, innovation, and impact in all areas of activity.
            </p>

            
          </div>
          {/* About me content end */}
          {/* about me personal detials start */}
        
          {/* about me personal details end */}
        </div>
        {/* projects rewards counting start */}
        <div
          className={
            "brands-grid separator-border mt-5 " +
            (darkTheme ? "separator-border-light" : "")
          }
        >
          <div className="row">
            <div className="col-6 col-md-4">
              <div className="featured-box text-center">
                <h4
                  className={
                    "text-12  mb-0 " +
                    (darkTheme ? "text-white-50" : "text-muted")
                  }
                >
                  <span>10</span>+
                </h4>
                <p className={"mb-0 " + (darkTheme ? "text-light" : "")}>
                  Years Experiance
                </p>
              </div>
            </div>
            <div className="col-6 col-md-4">
              <div className="featured-box text-center">
                <h4
                  className={
                    "text-12  mb-0 " +
                    (darkTheme ? "text-white-50" : "text-muted")
                  }
                >
                  <span>30M</span>+
                </h4>
                <p className={"mb-0 " + (darkTheme ? "text-light" : "")}>
                  Happy Clients & Stakeholders
                </p>
              </div>
            </div>
            <div className="col-6 col-md-4">
              <div className="featured-box text-center">
                <h4
                  className={
                    "text-12  mb-0 " +
                    (darkTheme ? "text-white-50" : "text-muted")
                  }
                >
                  <span>20</span>+
                </h4>
                <p className={"mb-0 " + (darkTheme ? "text-light" : "")}>
                  Projects Completed
                </p>
              </div>
            </div>
            {/* <div className="col-6 col-md-3">
              <div className="featured-box text-center">
                <h4
                  className={
                    "text-12  mb-0 " +
                    (darkTheme ? "text-white-50" : "text-muted")
                  }
                >
                  <span>38</span>
                </h4>
                <p className={"mb-0 " + (darkTheme ? "text-light" : "")}>
                  Get Awards
                </p>
              </div>
            </div> */}
          </div>
        </div>
        {/* projects rewards counting end */}
      </div>
    </section>
  );
};

export default AboutUs;
